import axios from "axios";

import { createAssetIdToken } from "./utils";
import { AppConfig } from "../configs/config";

export const reactQuerygetSubCategories = async (
	organizationId,
	token,
	groupItemIds,
	languageId,
	user,
	showHidden = false
) => {
	return axios.get(`${AppConfig.BASE_URL}/archive?action=getGroup`, {
		params: {
			organizationId,
			languageId,
			language: languageId,
			token,
			groupItemIds,
			showHidden,
			...(user?.userId ? { userId: user.userId } : { userId: 0 }),
			...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
		},
	});
};

// will get all categories based on groupItemId
export const reactQuerygetRootSubCategories = async (
	organizationId,
	key,
	languageId,
	groupItemId,
	user,
	source
) => {
	return await axios.get(`${AppConfig.BASE_URL}/archive?action=getGroups`, {
		cancelToken: source.token,
		params: {
			organizationId,
			languageId,
			groupItemId,
			token: key,
			...(user?.userId ? { userId: user.userId } : { userId: 0 }),
			...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
		},
	});
};

export const reactQueryfetchCategoryAssets = (
	organizationId,
	groupItemId,
	token,
	languageId,
	assetProperty,
	user,
	showHidden = false,
	limit = 50
) => {
	return axios.get(
		`${AppConfig.BASE_URL}/publishing/getAssets?version=06&series=true&limit=${limit}`,
		{
			params: {
				organizationId,
				languageId,
				language: languageId,
				groupItemId,
				token,
				showHidden,
				...(user?.userId ? { userId: user?.userId } : {}),
				...(user?.userToken ? { userToken: user?.userToken } : {}),
				...(assetProperty ? { assetProperty: assetProperty } : {}),
			},
		}
	);
};

// Get banner items
export const reactQuerygetBanner = async (organizationId, groupItemId, token, languageId, user) => {
	return axios.get(`${AppConfig.BASE_URL}/publishing?action=getBanners&version=05`, {
		params: {
			organizationId,
			languageId,
			token,
			groupItemId,
			series: false,
			...(user?.userId ? { userId: user.userId } : { userId: 0 }),
			...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
		},
	});
};

export const reactQueryGetPromo = async (
	organizationId,
	groupItemId,
	token,
	languageId,
	assetProperty,
	user
) => {
	return axios.get(`${AppConfig.BASE_URL}/publishing?action=getAssets&version=05`, {
		params: {
			organizationId,
			languageId,
			token,
			groupItemId,
			series: false,
			orderBy: "publishStart",
			limit: 3,
			...(user?.userId ? { userId: user.userId } : { userId: 0 }),
			...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
			...(assetProperty ? { assetProperty: assetProperty } : { assetProperty: "" }),
		},
	});
};

// GET CATEGORY BASED ON FOLDER TYPE LIKE SERIES/PAGES/FOLDERS ETC
export const getAllCategoriesByGroupType = (
	organizationId,
	key,
	languageId,
	groupTypeName = "Folders",
	user
) => {
	return axios.get(`${AppConfig.BASE_URL}/archive?action=getGroups`, {
		params: {
			organizationId,
			languageId,
			token: key,
			groupTypeName: groupTypeName,
			showHidden: false,
			...(user?.userId ? { userId: user.userId } : { userId: 0 }),
			...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
		},
	});
};

// get Latest Assets
export const reactQuerygetLatestAssets = async (
	organizationId,
	token,
	languageId,
	limit = 10,
	user
) => {
	// https://my.icareus.com/publishing?action=getLatestAssets&organizationId=70283302&limit=10&onlyVideos=true&token=03640b706d4a4728069a78b074ec9f45243d0275ed
	return axios.get(`${AppConfig.BASE_URL}/publishing?action=getLatestAssets`, {
		params: {
			organizationId,
			languageId,
			limit,
			onlyVideos: true,
			token,
			...(user?.userId ? { userId: user?.userId } : { userId: -1 }),
			...(user?.userToken ? { userToken: user?.userToken } : {}),
		},
	});
};

// TRENDING ASSETS
export const reactQuerygetTrendingAssets = async (
	organizationId,
	token,
	languageId,
	groupItemIds,
	limit = 10,
	days = 30,
	user
) => {
	// Otherwise it is just the same as the old call, but if you add &version=02 it will use new vod analytics, and when using version 02 you can also pass &groupItemIds=1234,1235 to only fetch assets from specific groups.
	return await axios.get(`${AppConfig.BASE_URL}/publishing?action=getMostPopular`, {
		params: {
			organizationId: organizationId,
			amount: limit,
			days: days,
			onlyVideos: true,
			token,
			languageId,
			version: "02",
			...(user?.userId ? { userId: user?.userId } : { userId: -1 }),

			...(user?.userToken ? { userToken: user?.userToken } : {}),
			...(groupItemIds ? { groupItemIds: groupItemIds } : {}),
		},
	});
	// const res1 = res.data.assets.map((el) => el.asset);
};

// TRENDING  SHOWS

export const reactQuerygetTrendingSerie = async (organizationId, token, days = 30, amount = 10) => {
	return await axios.get(`${AppConfig.BASE_URL}/publishing?action=getTopShows`, {
		params: {
			groupTypeName: "series",
			token,
			organizationId,
			days,
			amount,
		},
	});
};

export const reactQueryGetTrendingSeries = async (
	organizationId,
	token,
	days = 30,
	amount = 10
) => {
	console.log("Getting series?");
	return await axios.get(`${AppConfig.BASE_URL}/publishing?action=getTopCategories`, {
		params: {
			groupTypeName: "series",
			token,
			organizationId,
			days,
			amount,
		},
	});
};

export const reactQueryGetAsset = (
	organizationId,
	languageId,
	key,
	assetId,
	user,
	assetProperty,
	useMY
) => {
	console.log(organizationId, languageId, key, assetId, assetProperty, user);
	const token = createAssetIdToken(organizationId, assetId, languageId, key);

	// ALWAYS USE MY.ICAREUS HERE
	return axios.get(
		`${
			useMY ? AppConfig.BASE_URL_MY : AppConfig.BASE_URL
		}/publishing/getAsset?version=03&_=${Date.now()}`,
		{
			params: {
				organizationId,
				languageId,
				token,
				assetId,
				...(user?.userId ? { userId: user.userId } : { userId: 0 }),
				...(user?.userToken ? { userToken: user.userToken } : { userToken: "" }),
				...(assetProperty ? { assetProperty: assetProperty } : { assetProperty: "" }),
			},
		}
	);
};

// EPIOSDES FETCH

export const reactQueryfetchEpisodes = (organizationId, groupItemId, token, languageId, user) => {
	/*
	 * VERSION 06 of the getAssets call
	 * This call does not currently support the following parameters that were present in version 05:
	 *      - getRecordings
	 */

	return axios.get(`${AppConfig.BASE_URL}/publishing?action=getAssets&version=06`, {
		params: {
			organizationId,
			languageId,
			groupItemId,
			token,
			series: false,
			// the difference between this call and reactQueryfetchAssets  call is reactQueryfetchAssets will only get laresr asset in series which we need to show all in case of this API call

			//  series	boolean, Optional (Default true)	TRUE: will only fetch the latest asset in series. FALSE: will fetch all assets.

			...(user?.userId ? { userId: user?.userId } : {}),
			...(user?.userToken ? { userToken: user?.userToken } : {}),
			// assetProperty: "SVOD,TVOD",
		},
	});
};

// // Get user data
export const reactQueryGetUser = (organizationId, userToken) => {
	return axios.get(
		`${
			AppConfig.BASE_URL_MY
		}/user?action=getUserProfile&organizationId=${organizationId}&userToken=${userToken}&_=${Date.now()}`
	);
};
