// Depending upon mode we will send it to different component

import AssetsMode1 from "../components/Assets/Assets/AssetsMode1.js";
import AssetsMode2 from "../components/Assets/Assets/AssetsMode2.js";
import AssetsMode3 from "../components/Assets/Assets/AssetsMode3.js";
import AssetsMode4 from "../components/Assets/Assets/AssetsMode4.js";

const components = [AssetsMode1, AssetsMode2, AssetsMode3, AssetsMode4];

const Assets = (props) => {
	const { mode } = props.settings;

	const AssetsComponent = components[mode.split("_")[1] * 1 - 1];

	return <AssetsComponent {...props} />;
};

export default Assets;
