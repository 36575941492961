import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { useMyContext } from "../../contexts/StateHolder";
import { createToken } from "../../scripts/utils";
import { reactQuerygetSubCategories } from "../../scripts/dataHandlerReactquery";
import { PromoCard } from "./PromoCard";

import settings from "../../configs/config_settings.json";
import classes from "./Promo.module.css";

const PromoSignup = ({
	settings: { backgroundBanner, groupItemId, groupItemIdEn, routes, assetProperty },
}) => {
	const { organizationId, key } = settings.organization;

	const { user, language } = useMyContext();

	const [backgroundImagePromoContainer, setBackgroundImagePromoContainer] = useState(null);

	console.log("languagePromo", language);

	const showHidden = true;

	const fetchBannerImage = () => {
		const token = createToken(
			organizationId,
			language?.includes("fi") ? groupItemId : groupItemIdEn,
			key
		);

		return reactQuerygetSubCategories(
			organizationId,
			token,
			language?.includes("fi") ? groupItemId : groupItemIdEn,
			language,
			user,
			showHidden
		);
	};

	const {
		data: bannerData,
		isLoading: isBannerLoading,
		isSuccess: isBannerSuccess,
	} = useQuery(
		[`${language?.includes("fi") ? groupItemId : groupItemIdEn}getPromoBigBanner`, language],
		() => fetchBannerImage(),
		{
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,
		}
	);

	console.log("bannerData", bannerData);

	useEffect(() => {
		if (!isBannerLoading && isBannerSuccess) {
			setBackgroundImagePromoContainer(
				bannerData?.data?.data?.[0]?.bannerImageSmall ||
					bannerData?.data?.data?.[0]?.bannerImageMedium ||
					bannerData?.data?.data?.[0]?.bannerImageLarge
			);
		}
	}, [bannerData?.data?.data, isBannerLoading, isBannerSuccess]);

	if (backgroundImagePromoContainer) {
		return (
			<div
				className={classes.container}
				style={{
					position: "relative",
				}}
			>
				<img
					className={classes.backgroundImage}
					src={backgroundImagePromoContainer ? backgroundImagePromoContainer : null}
					alt="img"
					style={
						backgroundBanner && backgroundImagePromoContainer
							? { display: "flex", width: "100%" }
							: { display: "none" }
					}
					loading="lazy"
				/>

				<div
					className={classes.secondaryContainer}
					style={{
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					<PromoCard />
				</div>
			</div>
		);
	}
};

export default PromoSignup;
