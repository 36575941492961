import AssetsItem from "../AssetsItem/AssetsItem";

import settings from "../../../configs/config_settings.json";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";
import { createAssetIdToken } from "../../../scripts/utils";
import Header from "../../Common/Header/Header";
import AssetsCustomCarousel, {
	AssetsCustomCarouselSlickSettings,
} from "../../Common/CustomCarousel/AssetsCustomCarousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { reactQuerygetLatestAssets } from "../../../scripts/dataHandlerReactquery";
import { getUniqueSerieAndAssets } from "../../../lib/getUniqueSerieAndAssets";
import Translations from "../../Translations";

const LatestAssets = () => {
	const { organizationId, key } = settings.organization;
	const { language } = settings;
	let user = {};

	const fetchLatestAssets = () => {
		const token = createAssetIdToken(organizationId, "", null, key);
		return reactQuerygetLatestAssets(organizationId, token, language, 10, user);
	};

	const { data: latestAssets, isLoading } = useQuery(`latestAssets`, fetchLatestAssets, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
		select: (res) => getUniqueSerieAndAssets(res?.data?.assets),
	});
	const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];

	if (isLoading) {
		return (
			<>
				<Header title={<Translations text="latest.title" />} viewAll={false} />
				<AssetsCustomCarousel {...AssetsCustomCarouselSlickSettings}>
					{skeletonItem.map((el2) => (
						<SkeletonTheme key={el2}>
							<p>
								<Skeleton
									key={el2}
									width={"100%"}
									height={"325px"}
									style={{
										borderRadius: "10px",
									}}
								/>
							</p>
						</SkeletonTheme>
					))}
				</AssetsCustomCarousel>
			</>
		);
	}

	if (!isLoading && latestAssets?.length > 0) {
		return (
			<div>
				<Header title={<Translations text="latest.title" />} viewAll={false} />

				<AssetsCustomCarousel>
					{latestAssets?.map((el) => (
						<AssetsItem key={el?.id} asset={el} />
					))}
				</AssetsCustomCarousel>
			</div>
		);
	}
};

export default LatestAssets;
