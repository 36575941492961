import Logo from "../navigation/Logo";

import classes from "./Footer.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebook,

	// faInstagram, faTwitter
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import Translations from "../Translations";
import { useEffect, useState } from "react";
import { ROUTES } from "../../constants/route";
import { Link } from "react-router-dom";
import Colors from "../Colors";
import { getImageByKey } from "../../scripts/getImageByKey";

const SOME = [
	{
		id: 1,
		icon: (
			<FontAwesomeIcon
				icon={faFacebook}
				aria-hidden={false}
				title="Facebook"
				color={Colors.brand}
			/>
		),
		href: "https://www.facebook.com/profile.php?id=100063526635024",
		title: "Facebook",
	},
	// {
	// 	id: 2,
	// 	icon: (
	// 		<FontAwesomeIcon
	// 			icon={faInstagram}
	// 			aria-hidden={false}
	// 			title="Instagram"
	// 			color={Colors.brand}
	// 		/>
	// 	),
	// 	href: "https://www.instagram.com/",
	// 	title: "Instagram",
	// },
	// {
	// 	id: 3,
	// 	icon: (
	// 		<FontAwesomeIcon icon={faTwitter} aria-hidden={false} title="Twitter" color={Colors.brand} />
	// 	),
	// 	href: "https://twitter.com/ ",
	// 	title: "Twitter",
	// },
];
const Footer = () => {
	const { i18n, t } = useTranslation();

	const LINKS = [
		{
			title: <Translations text="footer.privacyTerms" />,

			href: `${ROUTES.PRIVACY_POLICY}`,
			id: 1,
		},
		{
			title: <Translations text="footer.tos" />,

			href: `${ROUTES.TOS}`,
			id: 2,
		},
		{
			title: <Translations text="navBar.faq" />,

			href: `${ROUTES.FAQ}`,
			id: 3,
		},
		{
			title: <Translations text="footer.contactUs" />,

			href: `${ROUTES.CONTACT}`,
			id: 4,
		},
	];

	const [updatedLINKS, setUpdatedLINKS] = useState(LINKS);

	useEffect(() => {
		setUpdatedLINKS(LINKS);

		// CHANGE LANG TEXT AGAIN WHEN LANGUAGE CHANGES
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n]);

	const ALL_STORE = [
		{
			id: 1,
			src: "playstore",

			href: "https://play.google.com/store/apps/details?id=fi.kotikino.mobile",
			label: "Play store",
		},
		{
			id: 2,
			src: "appstore",

			href: "https://apps.apple.com/fi/app/kotikino/id6471352620",
			label: "App store",
		},
	];

	return (
		<footer>
			<div className={classes.storesContainer}>
				{ALL_STORE.map((el) => (
					<a
						href={el?.href}
						target="_blank"
						key={el?.id}
						rel="noopener noreferrer"
						aria-label={`${el?.label} - opens in a new window`}
					>
						<img
							width={1920}
							height={1080}
							src={getImageByKey(el?.src)}
							alt={el?.label}
							className={
								el?.id === 1
									? `${classes.storeImage} ${classes.storeImageSpecial}`
									: `${classes.storeImage}`
							}
						/>
					</a>
				))}
			</div>
			<div className={`${classes.mainContainer} `}>
				<div className={classes.container}>
					<div className={classes.address}>
						<Logo src={"logo"} />
						<div className={`${classes.addressDetail} `}>
							<div>{t("footer.copyRight", { date: new Date().getFullYear() })}</div>
						</div>
					</div>
					<div className={`${classes.links} `}>
						{updatedLINKS.map((el) => (
							<Link
								to={el?.href}
								key={el?.id}
								aria-label={`${el?.title} `}
								className={classes.nav__item}
							>
								{el?.title}
							</Link>
						))}
					</div>

					<div className={classes.some}>
						{SOME.map((el) => (
							<a
								href={el?.href}
								target="_blank"
								key={el?.id}
								rel="noopener noreferrer"
								className={classes.nav__item}
								aria-label={`${el?.title} - opens in a new window`}
							>
								{el?.icon}
							</a>
						))}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
